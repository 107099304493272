import styled from 'styles/styled';

export const MOBILE_HEADER_HEIGHT = 81;
export const DESKTOP_HEADER_WIDTH = '165px';

export const Wrapper = styled.header<{
  horizontalHeader?: boolean;
  sticky: boolean;
  sticking: boolean;
}>`
  width: 100%;
  z-index: 2;

  background-color: ${({ sticking }) => (sticking ? '#00101C' : 'transparent')};
  position: ${({ sticky }) => (sticky ? 'sticky' : 'static')};
  top: -1px;
  transition: ${({ theme }) => theme.ui.transition('all')};
  box-shadow: ${({ sticking }) =>
    sticking
      ? '0 0 10px 10px rgba(0, 16, 28, 0.6)'
      : '0 0 5px 5px transparent'};

  @media (orientation: landscape) {
    margin-bottom: 1rem;
  }
`;

export const InnerWrapper = styled.div<{ sticking: boolean }>`
  align-items: center;
  display: flex;
  justify-content: space-between;
  max-width: ${({ theme }) => theme.dimensions.contentMaxWidthMedium}px;
  margin: 0 auto;
  padding: 20px;

  @media ${({ theme }) => theme.devices.large} {
    padding: ${({ sticking }) => (sticking ? '15px' : '30px')} 0;
    transition: ${({ theme }) => theme.ui.transition('padding')};
  }
`;

export const ProgressWrapper = styled.div<{
  sticking: boolean;
}>`
  background-color: #005aa0;
  height: 4px;
  left: 0;
  position: absolute;
  right: 0;
  opacity: ${({ sticking }) => (sticking ? 1 : 0)};
  transition: ${({ theme }) => theme.ui.transition('opacity')};
  top: 100%;
`;

export const ProgressIndicator = styled.div`
  background-color: ${({ theme }) => theme.palette.blogAccent};
  bottom: 0;
  content: '';
  left: 0;
  top: 0;
  position: absolute;
  transition: ${({ theme }) => theme.ui.transition('width')};
`;

export const Logo = styled.img`
  width: 151px;
  height: 33px;
  z-index: 3;

  @media ${({ theme }) => theme.devices.large} {
    width: 165px;
    height: 35px;
  }
`;

export const Toggle = styled.button`
  background-color: transparent;
  display: flex;
  cursor: pointer;
  position: relative;
  z-index: 3;
  width: 17px;
  height: 17px;

  @media ${({ theme }) => theme.devices.large} {
    display: none;
  }
`;

export const Hamburger = styled.div<{ isOpen?: boolean }>`
  background-color: ${({ theme }) => theme.palette.light};
  border-radius: 4px;
  width: 1rem;
  height: 1px;
  transition: all 0.3s linear;
  align-self: center;
  position: relative;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(-45deg)' : '0')};

  &:before,
  &:after {
    width: 1rem;
    height: 1px;
    background-color: ${({ theme }) => theme.palette.light};
    border-radius: 4px;
    content: '';
    position: absolute;
    left: 0;
    transition: all 0.1s linear;
  }

  &:before {
    transform: ${({ isOpen }) =>
      isOpen ? 'rotate(-90deg) translate(-6px, 0px)' : 'rotate(0deg)'};
    top: -6px;
  }

  &:after {
    opacity: ${({ isOpen }) => (isOpen ? '0' : '1')};
    transform: ${({ isOpen }) => (isOpen ? 'rotate(90deg)' : 'rotate(0deg)')};
    top: 6px;
  }
`;

export const MenuWrapper = styled.nav<{ isOpen: boolean }>`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.9);
  bottom: 0;
  display: flex;
  left: 0;
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  overflow: auto;
  padding-bottom: 30px;
  padding-top: 86px;
  pointer-events: ${({ isOpen }) => (isOpen ? 'all' : 'none')};
  position: fixed;
  right: 0;
  top: 0;
  transition: ${({ theme }) => theme.ui.transition('opacity')};
  z-index: 2;

  @media ${({ theme }) => theme.devices.small} {
    align-items: flex-start;
  }

  @media ${({ theme }) => theme.devices.large} {
    background-color: transparent;
    padding-bottom: 0;
    padding-top: 0;
    pointer-events: all;
    position: static;
    opacity: 1;
    overflow: visible;
  }
`;

export const MenuList = styled.ul`
  max-width: ${({ theme }) => theme.dimensions.contentMaxWidthMedium}px;
  margin: 0 auto;
  padding-left: 1.25rem;
  width: 100%;

  @media ${({ theme }) => theme.devices.small} {
    padding-top: 60px;
  }

  @media ${({ theme }) => theme.devices.large} {
    display: flex;
    justify-content: flex-start;
    padding-top: 0;
  }
`;

export const MenuItem = styled.li`
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSize.subtitleMobile};
  line-height: normal;
  text-transform: uppercase;

  & + & {
    margin-top: 40px;
  }

  a {
    display: block;
    opacity: 0.5;
    transition: ${({ theme }) => theme.ui.transition('opacity')};

    &:hover {
      opacity: 1;
    }
  }

  @media ${({ theme }) => theme.devices.large} {
    & + & {
      margin-left: 30px;
      margin-top: 0;
    }

    a {
      font-size: ${({ theme }) => theme.fontSize.smallBase};
    }
  }
`;

export const LogoWrapper = styled.div`
  z-index: 3;
`;
