import React, { memo, useEffect, useState } from 'react';
import { Link } from 'gatsby';

import { ROUTES, COOKIE_SETTINGS_LINK } from 'constants/common';

import {
  Copy,
  CopyWrapper,
  InnerWrapper,
  StyledButton,
  Title,
  Wrapper,
} from './styled';

const STORAGE_KEY = 'cookies';

const Cookies = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const accepted =
      typeof window !== 'undefined'
        ? window.localStorage.getItem(STORAGE_KEY)
        : false;

    if (!accepted) {
      requestAnimationFrame(() => setShow(true));
    }
  }, []);

  const onAccept = () => {
    setShow(false);
    window.localStorage.setItem(STORAGE_KEY, '1');
  };

  return (
    <Wrapper show={show}>
      <InnerWrapper>
        <CopyWrapper>
          <Title>This website uses cookies</Title>
          <Copy>
            This website uses cookies for its proper functioning, use of
            analytical, marketing and social plugins. For details, see the
            &nbsp;<Link to={ROUTES.privacyPolicy}>privacy policy</Link>&nbsp;If
            you agree to the use of cookies, click on the button &lsquo;I
            understand and accept&rsquo;. To edit the cookie settings in your
            browser click&nbsp;
            <a href={COOKIE_SETTINGS_LINK}>here</a>. You can revoke or change
            your consent at any time.
          </Copy>
        </CopyWrapper>
        <StyledButton onClick={onAccept}>I understand and accept</StyledButton>
      </InnerWrapper>
    </Wrapper>
  );
};

export default memo(Cookies);
