export const truncateString = (string: string, maxStringLength: number) =>
  string.length <= maxStringLength
    ? string
    : `${string.slice(0, maxStringLength)}...`;

export const iOS = () => {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

export const createBlogLink = (category: string, slug: string | undefined) =>
  `/${category.toLowerCase()}/${slug}`;
