import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';

import { FOOTER_NAV_LINKS, MINEBEST_URL, ROUTES } from 'constants/common';

import SocialMedia from 'components/SocialMedia';

import {
  Wrapper,
  InnerWrapper,
  AddressWrapper,
  Contact,
  SocialMediaWrapper,
  NavList,
  NavItem,
  Copyright,
  ContactWrapper,
  SocialsAndNavWrapper,
} from './styled';

const Footer = () => {
  const {
    footerContent: {
      footer: {
        companyName,
        contactUs,
        copyright,
        email,
        privacyPolicy,
        website,
      },
    },
  } = useStaticQuery(graphql`
    query Footer {
      footerContent: wpPage(slug: { in: "footer" }) {
        footer {
          contactUs
          companyName
          copyright
          email
          privacyPolicy
          website
        }
      }
    }
  `);

  return (
    <Wrapper>
      <InnerWrapper>
        <AddressWrapper>
          <h3>{companyName}</h3>
          <Copyright>
            <p>
              © {copyright}, {new Date().getFullYear()}
              <Link to={ROUTES.privacyPolicy}>{privacyPolicy}</Link>
            </p>
          </Copyright>
        </AddressWrapper>
        <ContactWrapper>
          <h3>{contactUs}</h3>
          <Contact>
            <p>
              <a href={MINEBEST_URL}>{website}</a>
              <br /> <a href={`mailto:${email}`}>{email}</a>
            </p>
          </Contact>
        </ContactWrapper>
        <SocialsAndNavWrapper>
          <SocialMediaWrapper>
            <SocialMedia />
          </SocialMediaWrapper>
          <nav>
            <NavList>
              {FOOTER_NAV_LINKS.map(({ name, path }) => (
                <NavItem key={name}>
                  <Link to={path}>{name}</Link>
                </NavItem>
              ))}
            </NavList>
          </nav>
        </SocialsAndNavWrapper>
      </InnerWrapper>
    </Wrapper>
  );
};

export default Footer;
