import React, { useState, useEffect } from 'react';
import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
import { useVisible } from 'react-hooks-visible';

import { NAV_LINKS, ROUTES } from 'constants/common';
import LogoMineBest from 'images/logo.svg';

import {
  Wrapper,
  Logo,
  Toggle,
  Hamburger,
  MenuWrapper,
  MenuList,
  MenuItem,
  LogoWrapper,
  InnerWrapper,
  ProgressIndicator,
  ProgressWrapper,
} from './styled';

interface Props {
  percentage?: number;
  sticky: boolean;
}

const Header = ({ percentage, sticky }: Props) => {
  const { pathname } = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [targetRef, visible] = useVisible<HTMLElement>();
  const sticking = (visible || 1) !== 1;

  const handleToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    setIsMenuOpen(false);
  }, [pathname]);

  useEffect(() => {
    document.body.style.overflow = isMenuOpen ? 'hidden' : 'initial';
  }, [isMenuOpen]);

  return (
    <Wrapper ref={targetRef} sticky={sticky} sticking={sticky && sticking}>
      <InnerWrapper sticking={sticky && sticking}>
        <LogoWrapper as={Link} to={ROUTES.root}>
          <Logo src={LogoMineBest} alt="MineBest" />
        </LogoWrapper>
        <Toggle onClick={handleToggleMenu}>
          <Hamburger isOpen={isMenuOpen} />
        </Toggle>
        <MenuWrapper isOpen={isMenuOpen}>
          <MenuList>
            {NAV_LINKS.map(({ name, path }) => {
              return (
                <MenuItem key={name}>
                  <Link
                    to={path}
                    activeStyle={{ fontWeight: 700, opacity: 1 }}
                    partiallyActive
                  >
                    {name}
                  </Link>
                </MenuItem>
              );
            })}
          </MenuList>
        </MenuWrapper>
        {sticky && (
          <ProgressWrapper sticking={sticking}>
            <ProgressIndicator
              style={{
                width: `${Math.min(
                  100,
                  (percentage || 0) * 100 + 5 * (percentage || 0),
                )}%`,
              }}
            />
          </ProgressWrapper>
        )}
      </InnerWrapper>
    </Wrapper>
  );
};

export default Header;
