import styled from 'styles/styled';
import { iOS } from 'utils';
export const Button = styled.button`
  background-color: transparent;
  border: 2px solid ${({ theme }) => theme.palette.light};
  border-radius: ${({ theme }) => theme.ui.borderRadius};
  color: ${({ theme }) => theme.palette.light};
  font-size: ${({ theme }) => theme.fontSize.smallBase};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  cursor: pointer;
  transition: ${({ theme }) => theme.ui.transition('all')};
  padding: 0.6rem 1.8rem;
  letter-spacing: normal;
  line-height: 1.07;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  width: ${iOS() ? 'auto' : 'fit-content'};

  &:hover {
    background-color: ${({ theme }) => theme.palette.light};
    color: ${({ theme }) => theme.palette.mediumDark};
    opacity: 1;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:focus {
    outline: none;
  }

  @media ${({ theme }) => theme.devices.large} {
    padding: 13px 70px;
  }
`;
