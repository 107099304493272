import styled from 'styles/styled';
import { Background } from 'components/SliderLayout/styled';

export const OuterWrapper = styled.div`
  /* overflow: hidden; */
`;

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  margin: 0 auto;
`;

export const Main = styled.main`
  flex-grow: 1;
  position: relative;
  width: 100%;

  @media ${({ theme }) => theme.devices.large} {
    height: 100%;
  }
`;

export const BackgroundImage = styled.div<Background>`
  background: ${({ backgroundImage, backgroundColor }) =>
    backgroundImage
      ? `center / cover no-repeat url(${backgroundImage})`
      : backgroundColor};
  transition: background 0.3s linear;
  bottom: 0;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: -1;
`;
