import styled from 'styles/styled';

export const Wrapper = styled.footer`
  border-top: 1px solid ${({ theme }) => theme.palette.lightOpacity};
  max-width: 100%;
  width: 100vw;
  z-index: 1;
`;

export const InnerWrapper = styled.div`
  max-width: ${({ theme }) => theme.dimensions.contentMaxWidthBig}px;
  padding: 52px 18px 78px;
  text-align: center;

  h3 {
    font-size: ${({ theme }) => theme.fontSize.subtitleMobile};
    margin-bottom: 10px;
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }

  a {
    text-decoration: underline;
  }

  @media ${({ theme }) => theme.devices.large} {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: ${({ theme }) => theme.dimensions.contentMaxWidthMedium}px;
    padding: 40px 0;

    h3 {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }
`;

export const SocialMediaWrapper = styled.section`
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  width: 100%;

  a {
    & + a {
      margin-left: 10px;
    }
  }

  @media ${({ theme }) => theme.devices.large} {
    margin-bottom: 20px;
  }
`;

export const NavList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  white-space: nowrap;

  @media ${({ theme }) => theme.devices.large} {
    margin-bottom: 6px;
  }
`;

export const NavItem = styled.li`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-transform: uppercase;

  & + & {
    &:before {
      content: '•';
      margin: 0 0.4em;
      display: inline-block;
    }
  }

  @media ${({ theme }) => theme.devices.large} {
    font-size: 1rem;
  }
`;

export const Copyright = styled.div`
  a {
    display: block;
  }

  @media ${({ theme }) => theme.devices.large} {
    margin: 0;

    p {
      font-size: 1rem;
    }
  }
`;

export const Contact = styled.div`
  margin-bottom: 30px;

  @media ${({ theme }) => theme.devices.large} {
    margin-bottom: 0;
  }
`;

export const ContactWrapper = styled.div`
  order: 3;

  @media ${({ theme }) => theme.devices.large} {
    text-align: right;
  }
`;

export const SocialsAndNavWrapper = styled.div`
  order: 2;
`;

export const AddressWrapper = styled.div`
  order: 1;
  margin-bottom: 30px;

  @media ${({ theme }) => theme.devices.large} {
    margin-bottom: 0;
    text-align: left;
  }
`;
