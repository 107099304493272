import React, { ReactNode } from 'react';

import Header from 'components/Header';
import Footer from 'components/Footer';
import Cookies from 'components/Cookies';

import { OuterWrapper, Wrapper, BackgroundImage, Main } from './styled';

interface Props {
  children: ReactNode;
  backgroundColor?: string;
  backgroundImage?: string;
  percentage?: number;
  sticky?: boolean;
}

const Layout = ({
  children,
  backgroundColor,
  backgroundImage,
  sticky = false,
  percentage,
}: Props) => {
  return (
    <>
      <OuterWrapper>
        <Wrapper>
          <BackgroundImage
            backgroundColor={backgroundColor}
            backgroundImage={backgroundImage}
          />
          <Header sticky={sticky} percentage={percentage} />
          <Main>{children}</Main>
          <Footer />
        </Wrapper>
      </OuterWrapper>
      <Cookies />
    </>
  );
};

export default Layout;
