import React from 'react';

import { SOCIAL_MEDIA_LINKS } from 'constants/common';

import { LinkWrapper } from './styled';

interface SocialMediaLink {
  url: string;
  icon: string;
  alt: string;
}

interface Props {
  socialMediaLinks?: SocialMediaLink[];
}

const SocialMedia = ({ socialMediaLinks = SOCIAL_MEDIA_LINKS }: Props) => {
  return (
    <>
      {socialMediaLinks.map(({ url, icon, alt }) => (
        <LinkWrapper
          key={icon}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={icon} alt={alt} />
        </LinkWrapper>
      ))}
    </>
  );
};

export default SocialMedia;
