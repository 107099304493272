import styled from 'styles/styled';

import { Button } from 'components/UI/Button';

export const Wrapper = styled.div<{ show: boolean }>`
  background-color: #f8f8f8;
  box-shadow: 0 2px 34px 0 rgba(0, 0, 0, 0.1);
  bottom: 0;
  left: 0;
  padding: 2rem;
  position: fixed;
  z-index: 2;
  right: 0;
  transform: ${({ show }) => `translateY(${show ? '0%' : '100%'})`};
  transition: ${({ theme }) => theme.ui.transition('transform')};

  @media ${({ theme }) => theme.devices.large} {
    padding: 3rem;
  }
`;

export const InnerWrapper = styled.div`
  max-width: ${({ theme }) => theme.dimensions.contentMaxWidthBig}px;
  margin: 0 auto;

  @media ${({ theme }) => theme.devices.medium} {
    align-items: center;
    display: flex;
  }
`;

export const CopyWrapper = styled.div`
  @media ${({ theme }) => theme.devices.medium} {
    margin-right: 3rem;
  }

  @media ${({ theme }) => theme.devices.large} {
    margin-right: 4rem;
  }

  a {
    text-decoration: underline;
  }
`;

export const Title = styled.h2`
  color: ${({ theme }) => theme.palette.dark};
  font-size: ${({ theme }) => theme.fontSize.title};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  margin-bottom: 0.5rem;
`;

export const Copy = styled.p`
  color: rgba(0, 0, 0, 0.69);
  line-height: normal;
  letter-spacing: 0.14px;
  margin-bottom: 1.25rem;

  @media ${({ theme }) => theme.devices.large} {
    margin-bottom: 0;
  }
`;

export const StyledButton = styled(Button)`
  border-color: ${({ theme }) => theme.palette.dark};
  color: ${({ theme }) => theme.palette.dark};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  margin: 0 auto;
  letter-spacing: 1.7px;
  text-transform: unset;
  transition: ${({ theme }) => theme.ui.transition('opacity')};
  padding: 0.75rem 1.5rem;

  &:hover {
    opacity: ${({ theme }) => theme.ui.opacity()};
  }

  @media ${({ theme }) => theme.devices.medium} {
    flex-shrink: 0;
  }
`;
