import React from 'react';
import { Helmet } from 'react-helmet';
import { SEOMeta } from 'types';
type Meta = {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content: any;
};

interface SEOProps {
  lang?: string;
  meta?: Meta[];
  image?: string;
  url?: string;
  imageType?: string;
  metadata: SEOMeta;
}
const fallbackDesc = 'Minebest fallback';
const fallbackTitle = 'MINEBEST';

const defaultMeta: SEOMeta = {
  seo: {
    metaDesc: fallbackDesc,
    metaKeywords: fallbackTitle,
    metaRobotsNofollow: 'follow',
    metaRobotsNoindex: 'index',
    opengraphAuthor: 'minebest',
    opengraphDescription: fallbackDesc,
    opengraphModifiedTime: '',
    opengraphPublishedTime: '',
    opengraphPublisher: '',
    opengraphSiteName: fallbackTitle,
    opengraphTitle: fallbackTitle,
    opengraphType: 'website',
    opengraphUrl: '',
    opengraphImage: {
      localFile: {
        name: '',
        extension: '',
        publicURL: '',
      },
      mimeType: '',
      mediaDetails: {
        height: '',
        width: '',
      },
    },
    title: fallbackTitle,
    twitterDescription: fallbackDesc,
    twitterTitle: fallbackTitle,
  },
};

const SEO = ({
  lang,
  meta = [],
  metadata = defaultMeta,
  image,
  imageType,
}: SEOProps) => {
  const {
    metaDesc,
    metaKeywords,
    metaRobotsNofollow,
    opengraphAuthor,
    opengraphDescription,
    opengraphModifiedTime,
    opengraphPublishedTime,
    opengraphPublisher,
    opengraphSiteName,
    opengraphTitle,
    opengraphUrl,
    opengraphImage,
    twitterDescription,
    twitterTitle,
  } = metadata.seo || {};

  const BASE_URL = process.env.GATSBY_PAGE_URL as string;
  const ogImage =
    image ||
    (opengraphImage && `${BASE_URL}${opengraphImage?.localFile.publicURL}`) ||
    'logo.svg';
  const ogImageType = opengraphImage?.mimeType || imageType || 'image/svg';

  return (
    <Helmet
      defer={false}
      htmlAttributes={{
        lang,
      }}
      title={metadata.seo?.title}
      meta={[
        {
          name: 'description',
          content: metaDesc,
        },
        {
          name: 'robots',
          content: `${metaRobotsNofollow}, index`,
        },
        {
          name: 'keywords',
          content: metaKeywords,
        },
        {
          property: 'og:author',
          content: opengraphAuthor,
        },
        {
          property: 'og:url',
          content: BASE_URL + opengraphUrl,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:site_name',
          content: opengraphSiteName,
        },
        {
          property: 'og:article:published_time',
          content: opengraphPublishedTime,
        },
        {
          property: 'og:article:modified_time',
          content: opengraphModifiedTime,
        },
        {
          property: 'og:article:author',
          content: opengraphPublisher,
        },
        {
          property: 'og:locale',
          content: lang,
        },
        {
          property: 'og:title',
          content: opengraphTitle,
        },
        {
          property: 'og:description',
          content: opengraphDescription,
        },
        { property: 'og:image', content: ogImage },
        {
          property: 'og:image:width',
          content: opengraphImage?.mediaDetails.width,
        },
        {
          property: 'og:image:height',
          content: opengraphImage?.mediaDetails.height,
        },
        { property: 'og:image:type', content: ogImageType },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:title',
          content: twitterTitle || opengraphTitle,
        },
        {
          name: 'twitter:description',
          content: twitterDescription || opengraphDescription,
        },
        {
          property: 'fb:app_id',
          content: process.env.FACEBOK_APP_ID,
        },
      ].concat(meta)}
    />
  );
};

export default SEO;
