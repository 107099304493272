import IconFacebook from 'images/icons/facebook.svg';
import IconLinkedIn from 'images/icons/linked-in.svg';
import IconTwitter from 'images/icons/twitter.svg';
import IconYouTube from 'images/icons/you-tube.svg';
import IconInstagram from 'images/icons/instagram.svg';
// import IconVine from 'images/icons/vine.svg';

export const SOCIAL_MEDIA_LINKS = [
  {
    url: `https://www.facebook.com/MineBestOfficial`,
    icon: IconFacebook,
    alt: `Facebook`,
  },
  {
    url: `https://linkedin.com/company/minebest`,
    icon: IconLinkedIn,
    alt: `LinkedIn`,
  },
  {
    url: `https://www.instagram.com/minebestofficial`,
    icon: IconInstagram,
    alt: `Instagram`,
  },
  {
    url: `https://twitter.com/minebest`,
    icon: IconTwitter,
    alt: `Twitter`,
  },
  {
    url: `https://www.youtube.com/channel/UCzW5jUTularszg2FmrlSpNw`,
    icon: IconYouTube,
    alt: `YouTube`,
  },
  // {
  //   url: `https://www.instagram.com/cloudbest_official/`,
  //   icon: IconVine,
  //   alt: `vine`,
  // },
];

export const ROUTES: Record<string, string> = {
  root: '/',
  privacyPolicy: '/privacy-policy',
  aboutUs: '/about-us',
  aboutEyal: '/about-us/eyal-avramovich',
  facilities: '/our-facilities',
  ourLabs: '/our-labs',
  blog: '/blog',
  contact: '/contact',
  messageSent: '/contact/message-sent',
};

export const NAV_LINKS = [
  {
    name: 'about us',
    path: ROUTES.aboutUs,
  },
  {
    name: 'facilities',
    path: ROUTES.facilities,
  },
  {
    name: 'blog',
    path: ROUTES.blog,
  },
  {
    name: 'contact',
    path: ROUTES.contact,
  },
];

export const FOOTER_NAV_LINKS = [
  {
    name: 'home',
    path: ROUTES.root,
  },
  ...NAV_LINKS,
];

export const SITEMAP_LINKS = [
  {
    name: 'Home',
    path: ROUTES.root,
  },
  {
    name: 'Privacy Policy',
    path: ROUTES.privacyPolicy,
  },
  ...NAV_LINKS,
];

export const MINEBEST_URL = 'https://www.minebest.com';
export const PRIVACY_MAIL = 'privacy@minebest.com';
export const CONTACT_MAIL = 'contact@minebest.com';
export const NAME = 'MineBest';
export const PRIVACY_MAIL_LINK = `mailto:${PRIVACY_MAIL}`;
export const CONTACT_MAIL_LINK = `mailto:${CONTACT_MAIL}`;

export const COOKIE_SETTINGS_LINK =
  'https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=en';

export const LEIA_URL = 'https://leiagabriel.com/';
